import { validateDestinationSlugFilter } from '~/helpers/validateDestinationSlugFilter.js'

export default defineNuxtRouteMiddleware(async (to) => {
  const { $strapiApi } = useNuxtApp()
  const { setRouteParams } = useSetRouteParams()

  const { valid, searchUrl } = await validateDestinationSlugFilter({
    $strapiApi,
    params: to.params,
  })

  if (!valid) {
    throw showError({ statusCode: 404 })
  }

  setRouteParams({
    slugEn: searchUrl.en,
    slugFr: searchUrl.fr,
  })
})
