import { getFacetsFilterWithDestination } from '~/helpers/strapi/getFacetsFilter'

export const validateDestinationSlugFilter = async ({ $strapiApi, params }) => {
  const destinationSlugs = [
    'location-chalets-luxe',
    'location-villas-luxe',
    'luxury-villas-rentals',
    'luxury-chalets-rentals',
  ]

  // If params is a destination slug
  if (destinationSlugs.includes(params.destination)) {
    try {
      const responseFilterData = await getFacetsFilterWithDestination({
        slug: params.filter,
        destination_slug: params.slug,
        $strapiApi,
      })

      if (responseFilterData) {
        const prefixSearchUrl = getPrefixUrl(responseFilterData.homeType.en)

        return {
          valid: true,
          searchUrl: {
            en: `${prefixSearchUrl.en}/${responseFilterData.urlEn}`,
            fr: `${prefixSearchUrl.fr}/${responseFilterData.urlFr}`,
          },
        }
      }
    } catch {
      return { valid: false }
    }
  }

  // Else show error page
  return { valid: false }
}

const getPrefixUrl = (homeType) => {
  const slugFr = `location-${homeType}s-luxe`
  const slugEn = `luxury-${homeType}s-rentals`

  return {
    fr: slugFr,
    en: slugEn,
  }
}
